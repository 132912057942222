import React from 'react'
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {MDXProvider} from "@mdx-js/react";
import components from "./mdx_components";
import {MDXRenderer} from "gatsby-plugin-mdx";

const EventBox = ({title, description, date, time, location, price, showDivider}) => (
  <Box>
    <Grid container justify="space-between" spacing={2}>
      <Grid item xs={12} sm={6} md={8}>
        <Typography variant="h6" gutterBottom={false}>{title}</Typography>
        <MDXProvider components={components}><MDXRenderer>{description}</MDXRenderer></MDXProvider>
      </Grid>
      <Grid item xs={12} sm="auto" md="auto">
        <Typography variant="body2" color="textSecondary" component="span">Am </Typography>
        <Typography color="textPrimary" component="span">{date}</Typography>
        <Typography variant="body2" color="textSecondary" component="span"> um </Typography>
        <Typography color="textPrimary" component="span">{time}</Typography>
        <Box>
          <Typography variant="body2" color="textSecondary" component="span">Wo: </Typography>
          <Typography variant="body1" component="span" gutterBottom={false}> {location}</Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="textSecondary" component="span">Preis: </Typography>
          <Typography variant="body1" component="span" gutterBottom={false}>{price} € </Typography>
        </Box>
      </Grid>
    </Grid>
    {showDivider ? <Divider/> : null}
  </Box>
)

export default EventBox
