import React from 'react'
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import EventBox from "./EventBox";

const EventsCard = ({events}) => {
  return (
  <Card>
    <Box m={1}>
      <Typography variant="h5">
        Termine
      </Typography>
      <Divider/>
      <Box my={1}>
        {events
          .map(e => (
            <EventBox title={e.frontmatter.title}
                      date={e.frontmatter.date}
                      description={e.body}
                      location={e.frontmatter.location}
                      price={e.frontmatter.price}
                      time={e.frontmatter.time}
                      key={e.frontmatter.title}
            />
          ))}
      </Box>
    </Box>
  </Card>
  )
}

export default EventsCard
